import ReactPlayer from "react-player";
import CreateBtn from "./CreateBtn";
import logo from "../images/Hexagon Verbyo abastru cu alb.svg";
import SignupImg from "../images/SignupImg.png";
import Playcircle from "../images/play-circle_svgrepo.com.svg";
import SignupImgSM from "../images/Untitled-5 2.png";
import PlaySmall from "../images/PlayS.svg";
import { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Signup({ handleClickPlay }) {
  const [email, setEmail] = useState("");
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    if (re.test(email)) {
      return true;
    } else {
      toast.error("Email is invalid");
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isCorrect = validateEmail(email);
    if (isCorrect) {
      fetch("https://api.business.verbyo.com/api/pwl-user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          type: "foundation",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "ok") {
            setEmail("");
            toast.success("Please Check your email");
          } else {
            toast.error("Email Already taken!");
          }
        });
    }
  };
  return (
    <div className="p-8">
      {" "}
      <ToastContainer />
      <div className=" shadow-SignShadow max-w-[382px] md:pl-[30px] md:pr-[30px] xl:pr-[69px] md:max-w-[1322px] xl:pl-[58px] pb-[50.1px] md:pb-[30px] xl:pb-[61.85px] mt-[-245.98px] md:mt-[-230.06px] mx-auto pt-[22.77px] xl:pt-[68.8px] w-full rounded-[15px] bg-[#FFF]">
        <div className="md:flex md:gap-[30px] xl:gap-0 md:items-center xl:items-start justify-between md:max-w-[1195px]">
          <div className="max-w-[711px] w-full">
            <div className="md:flex-row flex flex-col gap-[12px] mb-[30.06px] md:mb-[36.76px] items-center">
              <img src={logo} />
              <p className="text-[#262F61] text-[18px] md:text-[22px] font-bold leading-normal">
                VERBYO <span className="font-normal italic">for</span>{" "}
                FOUNDATIONS
              </p>
            </div>

            <h1 className="text-[24px] pl-[28px] md:pr-0 md:pl-0 pr-[20px] text-center md:text-start md:text-[28px] xl:text-[36px] font-bold leading-normal mb-[14.58px] md:mb-[4.71px] text-[#000]">
              Promote Your Cause through Real People on Social Networks
            </h1>

            <div
              onClick={() => {
                handleClickPlay(
                  window.innerWidth > 500
                    ? "https://youtu.be/Ti6zBsA5FNA"
                    : "https://youtu.be/RDYBAqMMuaU"
                );
              }}
              className="relative mb-[32.79px] px-[20px] md:hidden max-w-[380px] w-full"
            >
              <img
                alt="video thumbnail"
                className="min-h-[236.84px] h-full"
                src={SignupImgSM}
              />
              <img
                alt="video thumbnail"
                className="absolute inset-0 mx-auto mt-[82.89px]"
                src={PlaySmall}
              />
            </div>

            <p className="text-[18px] pl-[16px] md:pl-0 pr-[36px] md:pr-0 md:text-start text-center md:pb-[36.45px] mb-[30px] md:mb-[4.71px] leading-[30px] font-normal text-[#444]">
              The innovative marketing platform that enables foundations to
              raise awareness for their cherished causes. We leverage the
              influence of millions of authentic individuals who share your
              message on their social media accounts, and we{" "}
              <span className="font-semibold text-[#000]">
                do this for free
              </span>
              .
            </p>
            <form
              onSubmit={handleSubmit}
              className="md:flex-row items-center md:pl-0 flex flex-col gap-[22.77px] md:gap-[19px]"
            >
              <input
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required={true}
                className="border-[1px] outline-none pt-[11.33px] pb-[12.36px] pl-[44px] max-w-[241px] md:max-w-[339px] w-full border-solid placeholder-[#000] md:pl-[21.14px] md:pt-[13.24px] md:pb-[11.27px] min-h-[47.124] h-full border-black text-[16px] font-semibold leading-[24px] text-[#000]"
                placeholder="Enter your email"
              />
              <CreateBtn />
            </form>
          </div>

          <div className="relative hidden md:block  max-w-[380px] w-full">
            <img src={SignupImg} />
            <button className="hover:cursor-pointer hover:opacity-80">
              <img
                alt="video thumbnail"
                onClick={() => {
                  handleClickPlay(
                    window.innerWidth > 500
                      ? "https://youtu.be/Ti6zBsA5FNA"
                      : "https://youtu.be/RDYBAqMMuaU"
                  );
                }}
                className="absolute inset-0 mx-auto mt-[149px] mb-[191px]"
                src={Playcircle}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
