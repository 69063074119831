function Footer() {
  return (
    <div className="bg-[#F4F4F4] w-full">
      <div className="max-w-[1920px] mx-auto w-full xl:pt-[28.27px] xl:pb-[29.22px] pl-[55px] pb-[30.97px] pr-[56px] pt-[32.79px] xl:pl-[204px] xl:pr-[196px]">
        <div className="xl:flex-row flex gap-[9.11px] xl:gap-0 flex-col justify-between font-medium leading-[30px] text-[#000]">
          <p className="max-w-[155px] mx-auto xl:mx-0 xl:max-w-[278px] w-full text-[18px]">
            Verbyo LLC. 2019
          </p>
          <p
            onClick={() => {
              window.open(
                "https://business.verbyo.com/verbyo-terms?active=terms",
                "_blank"
              );
            }}
            className="max-w-[319px] cursor-pointer hover:underline text-center mx-auto xl:mx-0 xl:max-w-[417px] text-[14px] xl:text-[18px]"
          >
            Terms & Conditions | Privacy Policy | Contact
          </p>
        </div>
      </div>
    </div>
  );
}
export default Footer;
