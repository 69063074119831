import thePeople from "../images/the-people 1.png";
import Play from "../images/PlayH.svg";
import checkBullet from "../images/Vector.svg";
import CreateBtn from "./CreateBtn";
import PeopleSmall from "../images/peopleSmall.png";
import RealPeople from "../images/realpeople.png";

function HowItWorks({ handleClickPlay }) {
  const textCards = [
    {
      title: "Setup your campaign & launch it",
      info: "Create your campaign using our user-friendly campaign editor, choose your target audience (the individuals you want to deliver your message to), select your desired country, and launch your campaign.",
      No: "1",
    },
    {
      title: "People create posts on their social accounts",
      info: "Once the campaign is approved, your selected individuals will be notified, and they will begin sharing your campaign on their social media accounts, raising awareness for your cause among their digital friends.",
      No: "2",
    },
    {
      title: "Track your results with our dashboard",
      info: "We offer an extensive array of metrics in our dashboard to enhance your understanding and measurement of your campaign's performance. This enables you to optimize and enhance your campaign's efficiency.",
      No: "3",
    },
  ];

  const stats = [
    {
      number: "100k+",
      text: "real",
      type: "users",
      class: "order-4 xl:order-1",
    },
    {
      number: "500+",
      text: "social",
      type: "campaigns",
      class: "order-1 xl:order-2",
    },
    {
      number: "95+",
      text: "active",
      type: "foundations",
      class: "order-2 xl:order-3",
    },
    {
      number: "8",
      text: "targeted",
      type: "countries",
      class: "order-3 xl:order-4",
    },
  ];

  const checkBulletText = [
    { text: "Authenticiy & Relatability" },
    { text: "Trustworthiness & Diverse Audiences with various demographics " },
    { text: "Their content goes more often viral" },
    { text: "Long Term Commitment in promoting social causes" },
  ];
  const checkBulletText2 = [
    { text: "Profile authenticity verification" },
    { text: "Audience analysis" },
    { text: "Manual review of profiles" },
    { text: "Counter bot registration systems" },
  ];

  return (
    <div className="bg-[#F8F8F8] md:p-8 mb-[63.51px] xl:mb-[190.38px] mx-auto rounded-[15px] max-w-[1609px] w-full">
      <div className="pt-[61.03px] xl:pt-[98.02px]">
        <div className="md:mx-auto xl:max-w-[878px] mx-0 md:max-w-[600px] w-full">
          <h1 className="text-[#000] max-w-[251px] mx-auto mb-[23.68px] xl:mb-[47.12px] text-[26px] xl:text-[44px] font-bold text-center xl:max-w-[515px] w-full">
            How it works
          </h1>
          <p className="xl:max-w-[878px] md:max-w-[600px] xl:mb-[56.55px] max-w-[372px] md:ml-0 mx-auto w-full text-[#444] leading-[30px] font-normal text-center text-[18px]">
            We have developed a simple and easy way for you to create, launch
            and track your organic marketing campaigns on all major social
            networks.{" "}
          </p>
        </div>
      </div>

      <div className="max-w-[1342px] w-full mx-auto relative xl:pb-[127.04px]">
        {/* <div className="border-t-[1px] xl:block hidden absolute left-[184px] border-dashed top-[37.7px] max-w-[972px] w-full border-black"></div> */}

        <div className="flex flex-col xl:flex-row max-w-[1339px] mb-[45px] xl:mb-[116.87px] w-full justify-between">
          {textCards.map((item, index) => {
            return (
              <div
                key={index}
                className="max-w-[382px] mt-[65.5px] xl:mt-0 w-full mx-auto"
              >
                <div className="max-w-[80px] z-10 mx-auto mb-[42.41px] max-h-[75.39px] h-full pb-[17.31px] pl-[22px] pr-[23px] pt-[17.31px] w-full rounded-[50%] bg-white shadow-SignShadow">
                  <p className="text-[44px] font-bold leading-[100%] text-center text-[#0D2174]">
                    {item.No}
                  </p>
                </div>
                <div className="max-w-[382px] flex flex-col gap-[45.24px] w-full">
                  <h1 className="max-w-[350px] text-center  text-[22px] xl:text-[28px] ml-[7px] mr-[8px] font-bold  text-[#000]">
                    {item.title}
                  </h1>
                  <p className="text-[#444] leading-[30px] font-normal text-[18px] text-center">
                    {item.info}
                  </p>
                </div>
              </div>
            );
          })}
        </div>

        <div className="pl-[20px] xl:pl-0 xl:pr-0 pr-[28px]">
          <div className="max-w-[1320px] xl:ml-[13px] mb-[91.09px] xl:mb-[140.04px] w-full place-items-start gap-9 md:gap-0 grid grid-cols-2 flex-wrap md:flex justify-between bg-white rounded-[15px] xl:pr-[82.91px] pl-[28px] pt-[50.1px] pb-[54.66px] pr-[22px] xl:pb-[44.3px] xl:pl-[59.09px] xl:pt-[52.78px]">
            {stats.map((item, index) => {
              return (
                <div key={index} className={`max-w-[178px] ${item.class}`}>
                  <h1 className="text-[#0D2174] leading-[100%] text-[40px] xl:text-[44px] font-bold mb-[14.57px] xl:mb-[20.73px]">
                    {item.number}
                  </h1>
                  <p className="text-[#6F6F6F] leading-[100%] text-[20px] xl:text-[22px] font-normal">
                    {item.text}
                  </p>
                  <p className="text-[#6F6F6F] text-[24px] xl:text-[28px] font-bold">
                    {item.type}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex md:w-full xl:ml-[13px] pl-[25px] pr-[26px] xl:pl-0 xl:pr-0 xl:gap-[101px]">
          <div className="hidden xl:block relative h-fit">
            <img className="" src={thePeople} />
            <button
              onClick={() =>
                handleClickPlay(
                  window.innerWidth > 500
                    ? "https://youtu.be/e9j6EBjP6lo"
                    : " https://youtu.be/IorsPIa9rTI"
                )
              }
              className="absolute max-h-[86px] hover:opacity-80 h-full max-w-[91.6px] w-full m-auto inset-0"
            >
              <img src={Play} alt="play" />
            </button>
          </div>
          <div className="xl:pt-[22.07px] mx-auto xl:mx-0">
            <h1 className="text-[#000] md:text-left text-center md:max-w-[305px] max-w-[236px] md:text-[35px] mx-auto w-full xl:max-w-none mb-[35.53px] xl:ml-0 xl:mb-[27.99px] text-[26px] xl:text-[44px] leading-[100%] font-bold">
              Meet the community
            </h1>
            <img
              onClick={() =>
                handleClickPlay(
                  window.innerWidth > 500
                    ? "https://youtu.be/e9j6EBjP6lo"
                    : " https://youtu.be/IorsPIa9rTI"
                )
              }
              className="xl:hidden mx-auto mb-[29.15px]"
              src={PeopleSmall}
            />
            <p className="text-[18px] text-center xl:text-start font-normal mb-[13.19px] leading-[30px] max-w-[629px] w-full text-[#000]">
              The individuals who will share your social cause on their social
              media accounts are regular, everyday people 18 to 65 years old,
              like a friend you meet for coffee, a family member, or someone you
              admire.{" "}
              <span className="font-semibold">
                They constitute a significant 82% of today's social media users,
              </span>{" "}
              making them the ideal resource for promoting and disseminating
              messages across social media networks.
            </p>
            <div className="flex flex-col gap-[21.86px] xl:gap-[24.5px] mb-[49.19px] xl:mb-[51.84px]">
              {checkBulletText.map((item, index) => {
                return (
                  <div key={index} className="flex gap-[17px] items-center">
                    <img src={checkBullet} />
                    <p className="text-[#000] leading-[30px] font-semibold text-[18px]">
                      {item.text}
                    </p>
                  </div>
                );
              })}
            </div>

            <div className="hidden md:mx-auto xl:mx-0 max-w-[241px] md:mb-[40px] xl:mb-0 md:block">
              <CreateBtn
                onClick={() => {
                  window.open("https://business.verbyo.com/sign-up", "_blank");
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex  mt-32 md:w-full xl:ml-[13px] pl-[25px] pr-[26px] xl:pl-0 xl:pr-0 xl:gap-[101px]">
          <div className="xl:pt-[22.07px] mx-auto xl:mx-0">
            <h1 className="text-[#000] md:max-w-[330x] md:text-left text-center max-w-[236px] md:text-[35px] mx-auto w-full xl:max-w-[420px] mb-[35.53px] xl:ml-0 xl:mb-[27.99px] text-[26px] xl:text-[44px] leading-[100%] font-bold">
              Real people, with real emotions
            </h1>
            <div
              onClick={() =>
                handleClickPlay(
                  window.innerWidth > 500
                    ? "https://youtu.be/xehptNUoBdk"
                    : "https://youtu.be/xehptNUoBdk"
                )
              }
              className="relative xl:hidden "
            >
              <img
                alt="sdfre"
                className="xl:hidden mx-auto mb-[29.15px]"
                src={RealPeople}
              />
              <img
                className="absolute top-0 left-0 m-auto inset-0 xl:hidden "
                src={Play}
                alt="play"
              />
            </div>
            <p className="text-[18px] text-center xl:text-start font-normal mb-[13.19px] leading-[30px] max-w-[629px] w-full text-[#000]">
              Our mission is to create an open and transparent marketing
              environment, actively combating misinformation, hate speech, and
              the distribution of fake news. To achieve this,{" "}
              <span className="font-semibold">
                we have implemented several filters to verify and ensure that
                individuals posting are genuine, thereby preventing bot
                registrations or the creation of fake profiles.
              </span>
            </p>
            <div className="flex flex-col gap-[21.86px] xl:gap-[24.5px] mb-[49.19px] xl:mb-[51.84px]">
              {checkBulletText2.map((item, index) => {
                return (
                  <div key={index} className="flex gap-[17px] items-center">
                    <img src={checkBullet} />
                    <p className="text-[#000] leading-[30px] font-semibold text-[18px]">
                      {item.text}
                    </p>
                  </div>
                );
              })}
            </div>

            <div className="hidden md:mx-auto xl:mx-0 max-w-[241px] md:mb-[40px] xl:mb-0 md:block">
              <CreateBtn
                onClick={() => {
                  window.open("https://business.verbyo.com/sign-up", "_blank");
                }}
              />
            </div>
          </div>
          <div className="hidden xl:block relative h-fit">
            <img alt="sdad" className="" src={RealPeople} />
            <button
              onClick={() =>
                handleClickPlay(
                  window.innerWidth > 500
                    ? "https://youtu.be/xehptNUoBdk"
                    : "https://youtu.be/xehptNUoBdk"
                )
              }
              className="absolute max-h-[86px] hover:opacity-80 h-full max-w-[91.6px] w-full m-auto inset-0"
            >
              <img src={Play} alt="play" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default HowItWorks;
