import logo from "../images/Hexagon Verbyo abastru cu alb.svg";
import CreateBtn from "./CreateBtn.jsx";
import ArrowBlack from "../images/Arrow 6B.svg";

function Header() {
  return (
    <div className="max-w-[1920px] hidden  xl:flex justify-between pl-[85px] pr-[103px] py-[18.85px] w-full">
      <div className="flex gap-[12px] items-center">
        <img src={logo} />
        <p className="text-[#262F61] text-[22px] font-bold leading-normal">
          VERBYO <span className="font-normal italic">for</span> FOUNDATIONS
        </p>
      </div>

      <div className="flex gap-[29px]">
        <button
          onClick={() => {
            window.open("https://business.verbyo.com/login", "_blank");
          }}
          className="border-[1px] hover:bg-black/10 max-h-[47.124px] h-full max-w-[200px] w-full whitespace-nowrap border-solid border-black flex gap-[37px] items-center py-[12.25px] pl-[74px] pr-[19px]"
        >
          <p className="text-[#000] text-[16px] font-semibold leading-[24px]">
            Log In
          </p>
          <img src={ArrowBlack} />
        </button>
        <CreateBtn
          onClick={() => {
            window.open("https://business.verbyo.com/sign-up", "_blank");
          }}
        />
      </div>
    </div>
  );
}

export default Header;
