import './input.css'
import logo from './logo.svg';
import './App.css';
import FoundationsPage from './Components/FoundationsPage';
import Header from "./Components/Header.jsx";
import Footer from "./Components/Footer.jsx";

function App() {
  return (
    <div className="overflow-hidden font-Montserrat">
      <div className="max-w-[1920px] mx-auto w-full">
        <Header />
        <FoundationsPage />
      </div>
      <Footer />
    </div>
  );
}

export default App;
