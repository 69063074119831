import { useState } from "react";
import ReactPlayer from "react-player";
import CampaignCards from "./CampaignCards.jsx";
import FAQ from "./FAQ.jsx";
import Signup from "./Signup.jsx";
import HowItWorks from "./HowItWorks.jsx";
import wwf from "../images/wwf 1.svg";
import ocean from "../images/ocean-cleanup 1.svg";
import isco from "../images/isco 2 (1).svg";
import unicef from "../images/unicef 1.svg";
import theirworld from "../images/theirworld 1.svg";
import verbyo from "../images/withname 1.svg";
import volunteers from "../images/volunteers 1.png";
import raffles from "../images/raffles 1.png";
import ugc from "../images/ugc 1.png";
import organic from "../images/organic-post 1.png";
import viral from "../images/Group 8.svg";
import lawyer from "../images/Group 9.svg";
import Stats from "../images/Group 10.svg";
import shield from "../images/Group 14.svg";
import iconChart from "../images/Group 12.svg";
import network from "../images/Group 13.svg";
import CloseCross from "../images/Cross_icon_(white).svg.png";
import ugcS from "../images/ugc 1s.png";
import volunteersS from "../images/volunteers 1s.png";
import rafflesS from "../images/raffles 1s.png";
import organicS from "../images/organic-post 1s.png";

function FoundationsPage() {
  const power = [
    { img: viral, text: "Amplified Reach" },
    { img: lawyer, text: "Authentic Advocacy" },
    { img: Stats, text: "Measurable Impact" },
    { img: shield, text: "Ethical Marketing" },
    { img: iconChart, text: "Diverse Demographics" },
    { img: network, text: "Viral Potential" },
  ];

  const campaignCard = [
    {
      img: organic,
      title: "Organic Campaigns",
      text: "People will post your campaign on their social media accounts using the creatives that you provide.",
      gap: "xl:gap-[24.5px] gap-[14.57px]",
      gap2: "gap-[27.33px] xl:gap-0",
      imgS: organicS,
    },
    {
      img: ugc,
      title: "Scripted UGC Campaigns",
      text: "Ask people to create content based on your script and then post it on their social media accounts.",
      gap: "xl:gap-[20.73px] gap-[14.57px]",
      gap2: "gap-[27.33px] xl:gap-0",
      imgS: ugcS,
    },
    {
      img: raffles,
      title: "Launch Raffles",
      text: "Launch a raffle to motivate users to share more social causes on their social profiles and enhance awareness of your foundation within our community.",
      gap: "xl:gap-[13.19px] gap-[8.2px]",
      gap2: "gap-[12.75px] xl:gap-0",
      imgS: rafflesS,
    },
    {
      img: volunteers,
      title: "Find volunteers",
      text: "Initiate a campaign to recruit volunteers for your project from our users or their connections.",
      gap: "xl:gap-[17.91px] gap-[13.66px]",
      gap2: "gap-[27.33px] xl:gap-0",
      imgS: volunteersS,
    },
  ];

  const [play, setPlay] = useState(false);

  const handleClickPlay = (link) => {
    setPlay(link);
  };

  return (
    <div className={`${play ? "overflow-y-hidden" : "overflow-auto"}`}>
      <div className="bg-hero2 bg-no-repeat bg-cover bg-center md:bg-hero pb-[292.41px] xl:pb-[354.47px] pt-[116.6px] xl:pt-[185.47px]">
        <h1 className="text-[#FFF] md:p-4 md:max-w-[600px] max-w-[389px] max-h-[110.227px] xl:max-w-[1192px] w-full xl:max-h-[114px] h-full font-bold text-[26px] xl:text-[50px] text-center mx-auto">
          Organic Social Media Marketing Campaigns for Foundations
        </h1>
      </div>

      <Signup handleClickPlay={handleClickPlay} />

      <div className="md:max-w-[800px] p-8  max-w-[348px] mt-[17.36px] xl:mt-0 xl:max-w-[1298px] mx-auto w-full">
        <p className="text-[#181818] mx-auto md:max-w-[600px] py-[49.5px] xl:py-0 max-w-[348px] xl:max-w-[826px] w-full text-[16px] md:text-[22px] font-bold xl:mt-[99.9px] leading-normal text-center">
          Helping over 95+ foundations to promote their cause all over the world
        </p>
        <div className="grid grid-cols-2 md:grid-cols-3 place-items-center xl:flex justify-between flex-wrap xl:flex-nowrap mb-[26.32px] xl:mb-[100.85px] xl:max-h-[158.3px] xl:h-full">
          <img
            alt="close button"
            className="basis-[50%] md:basis-0"
            src={wwf}
          />
          <img alt="close button" className=" hidden md:block" src={ocean} />
          <img
            alt="close button"
            className="basis-[50%] md:basis-0"
            src={isco}
          />
          <img
            alt="close button"
            className="basis-[50%] md:basis-0"
            src={unicef}
          />
          <img
            alt="close button"
            className="basis-[50%] md:basis-0 max-w-[221px] w-full"
            src={theirworld}
          />
          <img className="hidden md:block" src={verbyo} />
        </div>
      </div>

      {play && (
        <div className="w-screen h-screen fixed inset-0 flex items-center justify-center  px-4 md:px-[50px] pt-14 md:pt-[70px] z-30  bg-black/60">
          <ReactPlayer
            controls
            width={window.innerWidth > 500 ? "75%" : "95%"}
            height="90%"
            url={play}
          />
          <img
            alt="close button"
            onClick={() => handleClickPlay(false)}
            className="max-w-[21px] max-h-[21px]  hover:cursor-pointer top-5 right-12 absolute"
            src={CloseCross}
          />
        </div>
      )}

      <HowItWorks handleClickPlay={handleClickPlay} />

      <div className="max-w-[1330px] mb-[87.45px] xl:mb-[121.58px] mx-auto w-full">
        <h1 className="text-[26px] xl:text-[44px] mb-[19.79px] mx-auto xl:mb-[106.5px] max-w-[392px] md:max-w-[600px] xl:max-w-[819px] w-full font-bold leading-normal text-[#000] text-center">
          Ways to spread your message accross social networks
        </h1>
        <div className="grid grid-cols-2s xl:p-6 xl:grid-cols-2 max-w-[380px] xl:max-w-[1330px] w-full mx-auto xl:mx-0 gap-x-[30px] gap-y-[37.7px]">
          {campaignCard.map((card, index) => {
            return (
              <CampaignCards
                key={index}
                title={card.title}
                text={card.text}
                img={card.img}
                gap={card.gap}
                gap2={card.gap2}
                imgS={card.imgS}
              />
            );
          })}
        </div>
      </div>

      <div className="max-w-[1450px] mx-auto bg-[#F8F8F8] rounded-[15px] pb-[83.88px] pt-[54.66px] xl:pt-[74.46px] w-full">
        <h1 className="text-[#000] md:mx-auto ml-[42px] mb-[54.66px] md:max-w-[500px] xl:mx-auto text-[26px] xl:text-[44px] font-bold text-center max-w-[329px] xl:max-w-[927px]">
          Engage the power of real people for your cause
        </h1>
        <div className="grid gap-y-[36.44px] md:grid-cols-2 xl:gap-y-[47.2px] pl-[18px] xl:pl-[85px] grid-cols-1 xl:grid-cols-3 justify-between">
          {power.map((item, index) => {
            return (
              <div key={index} className="flex gap-[25px] items-center">
                <img src={item.img} />
                <p className="text-[#000] max-w-[286px] w-full text-[22px] xl:text-[24px] font-bold">
                  {item.text}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      <a
        href="./Verbyo_for_Foundations_Guide.pdf"
        target="_blank"
        rel="noreferrer"
        download={true}
        className="mx-auto hidden  hover:bg-black/10 xl:block w-full mb-[111.88px] max-w-[339px] mt-[96.88px]"
      >
        <button className="text-[16px] pt-[13px] pb-[11.12px] text-[#000] max-w-[339px] w-full border-[1px] border-solid border-black font-semibold leading-[24px]">
          download full presentation .pdf
        </button>
      </a>

      <FAQ />
    </div>
  );
}

export default FoundationsPage;
