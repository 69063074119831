import { useState } from "react";
import line from "../images/Line 6.svg";
import FAQquestions from "./FAQquestions";

function FAQ() {
  const [FAQ, setFAQ] = useState([
    {
      question: "Why is Verbyo Free for Foundations?",
      answer:
        "Verbyo is committed to supporting the missions of foundations by offering its marketing platform for free. Our primary goal is to amplify the voices of foundations and enable them to raise awareness for the important causes they champion. We believe that by providing this service without cost, we can help foundations allocate more of their resources toward their impactful initiatives, ultimately driving greater positive change in the world. Additionally, we aim to build a strong and inclusive community of individuals who are eager to contribute to these causes, creating a network of like-minded people passionate about making a difference. By offering our platform for free to foundations, we're dedicated to fostering collaboration and facilitating their outreach efforts, making it easier for them to reach a global audience of potential supporters.",
      open: true,
      id: Math.floor(Math.random() * 1000),
    },
    {
      question: "Platforms and countries supported for advertising",
      answer:
        "You can promote your cause on all major social platforms such as Facebook, Instagram, TikTok, and Twitter. We are actively working on adding more platforms in the future. Currently, we are present in eight countries worldwide and plan to launch in an additional six countries in 2024.",
      open: false,
      id: Math.floor(Math.random() * 1000),
    },
    {
      question: "Why people post without getting paid?",
      answer:
        "Verbyo is more than just a marketing platform; it's a community of people committed to driving change in their communities and worldwide. While individuals are not compensated with currency, each time they share a social cause, they earn Social Points. These points can be later redeemed for entries in our platform's raffles or for discounts from our partners.",
      open: false,
    },
    {
      question: "What type of social causes can be advertised with Verbyo?",
      answer:
        "Verbyo is an inclusive and cost-free platform that welcomes all types of foundations. At present, we actively support the promotion of social causes related to education, animal protection, environmental conservation, green energy initiatives, and wildlife protection. Our mission is to amplify the voices of organizations dedicated to these crucial causes, fostering positive change in our communities and the world at large. While these areas currently receive our priority, we are continuously exploring opportunities for expansion to include additional social causes in the future. If your social cause is not listed, please feel free to inform us, and we may consider adding it to our list.",
      open: false,
      id: Math.floor(Math.random() * 1000),
    },
    {
      question: "Do I need to verify my foundation identity?",
      answer:
        "Yes, we take a significant responsibility for the content advertised through our platform, which is why we require all foundations that sign up with us to verify their accounts. This verification process ensures the authenticity and legal establishment of your foundation. We believe that this measure helps maintain a high level of trust and responsibility within our community, assuring our users that the causes promoted through our platform are credible and genuine.",
      open: false,
      id: Math.floor(Math.random() * 1000),
    },
    {
      question: "Why should I use Verbyo to promote my foundation?",
      answer:
        "Foundations benefit from using Verbyo for cause promotion. Verbyo connects them with everyday social media users for authentic, cost-effective outreach. Its diverse demographics and engaged community offer broad impact. User-friendly campaign management and detailed metrics enhance campaign performance. Viral potential, long-term commitment, and adaptability make Verbyo a powerful tool for cause advocacy.",
      open: false,
      id: Math.floor(Math.random() * 1000),
    },
  ]);

  const handleToggle = (id) => {
    const newFAQ = FAQ.map((item) => {
      if (item.id === id) {
        return {...item, open: true}
      } else {
        return {...item, open: false}
      }
    });
    setFAQ(newFAQ);
  };

  return (
    <div className="xl:pb-[158.47px] mb-[100px] xl:mb-0  mt-[87.37px] xl:mt-0 mx-auto max-w-[943px]">
      <div className="max-w-[943px] pl-[20px] xl:pl-0 pr-[32px] xl:pr-0 w-full">
        <h1 className="text-[#000] md:max-w-[600px] max-w-[355px] mx-auto text-center text-[24px] xl:text-[44px] font-bold xl:max-w-[819px] w-full mb-[92.36px]">
          Answers that might help you understand better what we do
        </h1>
        <div>
          {FAQ.map((item, index) => {
            return (
              <FAQquestions
                key={index}
                question={item.question}
                answer={item.answer}
                handleToggle={() => handleToggle(item.id)}
                open={item.open}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FAQ;
