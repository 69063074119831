import { useState } from "react";
import line from "../images/Line 6.svg";
import Plus from "../images/Plus.svg";
function FAQquestions({ question, answer, handleToggle, open }) {
  // const [open, setOpen] = useState(false);
  // const handleClickToggle = () => {
  //   setOpen(!open);
  // };
  return (
    <div className="">
      <div onClick={handleToggle} className={`${!open ? "mb-[22.52px] xl:mb-[30.16px]" : "mb-0" } pb-[16.4px] xl:pb-[27.33px] hover:cursor-pointer flex justify-between border-b-[1px] border-solid border-[#C7C7C7]`}>
        <h1 className="text-[#000] xl:max-w-[739px] md:max-w-[400px] max-w-[296px] w-full text-[18px] xl:text-[24px] font-bold">{question}</h1>
        {open && <img className="h-fit mt-[19.79px]" src={line} />}
        {!open && <img src={Plus} />}
      </div>
      {open && <p className="mt-[22.52px] xl:mt-[34.87px] leading-[30px] text-[16px] xl:text-[18px] mb-[30.16px]">{answer}</p>}
    </div>
  );
}

export default FAQquestions;
