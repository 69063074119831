import ArrowWhite from "../images/Arrow 6.svg";

function CreateBtn({ onClick }) {
  return (
    <div className="max-w-[241px] xl:max-w-[200px] w-full">
      <button
        onClick={onClick && onClick}
        className="flex bg-[#000] hover:opacity-[0.80] max-h-[47.124px] h-full whitespace-nowrap items-center gap-[19px] xl:gap-[12px] py-[11.84px] pl-[51px] pr-[27.31px] xl:py-[12.5px] xl:pl-[25px] xl:pr-[19px]"
      >
        <p className="text-[#FFF] text-[16px] font-semibold leading-[24px]">
          Create account
        </p>
        <img src={ArrowWhite} />
      </button>
    </div>
  );
}

export default CreateBtn;
