import ArrowB from "../images/Arrow 6B.svg";

function CategoryCards({ img, title, text, gap, imgS, gap2 }) {
  return (
    <div
      onClick={() => {
        window.open("https://business.verbyo.com/sign-up", "_blank");
      }}
      className="xl:flex-row group hover:cursor-pointer flex flex-col max-w-[378px] xl:max-w-[650px] rounded-[15px] shadow-SignShadow gap-[33.7px] xl:gap-[31px] w-full"
    >
      <img className="hidden xl:block max-w-[238px] h-full" src={img} />
      <img className="xl:hidden " src={imgS} />
      <div
        className={`${gap2} flex flex-col justify-between pl-[17px] pr-[32px] xl:pl-0 xl:pr-0 xl:pt-[36.76px] pb-[24.5px]`}
      >
        <div
          className={`${
            gap ? gap : "gap-0"
          } justify-between xl:justify-normal flex flex-col`}
        >
          <h1 className={`text-[22px] xl:text-[24px] font-bold text-[#000]`}>
            {title}
          </h1>
          <p
            className={` text-[#000] max-w-[329px] w-full font-medium xl:font-normal leading-[30px] xl:leading-[24px] text-[18px]`}
          >
            {text}
          </p>
        </div>
        <div className="flex relative pb-[8.48px] max-w-[175px] w-full justify-between items-center">
          <p className="text-[#000] text-[16px] font-semibold leading-[24px]">
            Start a campaign
          </p>
          <img
            className="group-hover:translate-x-[3.5px] transition-all duration-[.3s] ease-in"
            src={ArrowB}
          />
          <div className="absolute max-w-[0] transition-all ease-in-out duration-[1s] group-hover:max-w-[400px] w-full top-[30px] border-t-[2px] border-solid border-black"></div>
        </div>
      </div>
    </div>
  );
}

export default CategoryCards;
